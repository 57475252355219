









































































































































import { Component, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";
import MfrMenu from "@/components/manufacturer/MfrMenu.vue";
import Icon from "@/components/reusable/Icon.vue";
import Table from "@/components/reusable/table/Table.vue";

@Component({
  components: {
    draggable,
    MfrMenu,
    Icon
  }
})
export default class MfrTable extends Table {}
